import { Link } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <main className="container py-20 prose dark:prose-dark">
        <h2>
          Ups, da ist etwas schief gelaufen. Die von dir angeforderte Seite
          existiert nicht.
        </h2>
        <h5>
          <Link to="/">Zur Startseite</Link>
        </h5>
      </main>
    </Layout>
  );
};

export default NotFoundPage;
